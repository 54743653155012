import React from "react"
import { graphql } from "gatsby"

import IndexLayout from "../components/IndexLayout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <IndexLayout location={this.props.location} title={siteTitle}>
        <SEO title="404: Page Introuvable" />
        <h1>
          <span role="img" aria-label="emoji terrified face">
            😱
          </span>{" "}
          Page introuvable{" "}
          <span role="img" aria-label="emoji terrified face">
            😱
          </span>
        </h1>
        <p>
          La page que vous essayez de visiter n'existe pas ... C’est triste,
          mais vous devriez en trouver beaucoup d'autres qui existent bel et
          bien !
        </p>
      </IndexLayout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
